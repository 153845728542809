<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="apprline2"
          title="회람"
          tableId="apprline2"
          topBorderClass="topcolor-orange"
          :columnSetting="false"
          :isFullScreen="false"
          :hideBottom="true"
          :usePaging="false"
          :filtering="false"
          gridHeight="350px"
          :columns="gridline2.columns"
          :data="gridline2.data"
          :isExcelDown="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="apprline"
          title="결재이력"
          tableId="apprline"
          topBorderClass="topcolor-orange"
          :columnSetting="false"
          :isFullScreen="false"
          :hideBottom="true"
          :usePaging="false"
          :filtering="false"
          gridHeight="350px"
          :columns="gridline.columns"
          :data="gridline.data"
          :isExcelDown="false"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'approvalKindName'">
              <span class="text-weight-bold" :class="getLineKindColor(props.row.approvalKindCd)">{{props.row.approvalKindName}}</span>
            </template>
            <template v-if="col.name === 'approvalStatusName'">
              <span :class="getLineKindColor2(props.row.approvalStatusName)">{{props.row.approvalStatusName}}</span>
            </template>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'appr-history-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          sysApprovalRequestId: '',
        }
      },
    },
  },
  data() {
    return {
      dragging: false,
      gridline: {
        columns: [
          {
            name: 'approvalKindName',
            field: 'approvalKindName',
            label: '결재구분',
            align: 'center',
            style: 'width: 80px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재상태',
            align: 'center',
            style: 'width: 100px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'returnReason',
            field: 'returnReason',
            label: '반려사유',
            align: 'left',
            sortable: false,
          },
          {
            name: 'approvalDt',
            field: 'approvalDt',
            label: '결재일시',
            align: 'center',
            style: 'width: 160px',
            sortable: false,
          },
        ],
        data: [],
      },
      gridline2: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.sys.appr.line.list.url;
      this.getApprLine();
    },
    getApprLine() {
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      this.$http.url = this.listUrl;
      this.$http.param = {
        sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          let lineData1 = _result.data.filter( x => {
            return x.approvalKindCd == 'AKC0000001' || x.approvalKindCd == 'AKC0000002' || x.approvalKindCd == 'AKC0000003' || x.approvalKindCd == 'AKC0000004' || x.approvalKindCd == 'AKC0000010'
          });
          let lineData2 = _result.data.filter( x => {
            return x.approvalKindCd == 'AKC0000009'
          });
          this.gridline.data = lineData1;
          this.gridline2.data = lineData2;
        }
      },
      () => {
      });
    },
    getLineKindColor(_cd) {
      let color = '';
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      switch(_cd) {
        case 'AKC0000010':
        case 'AKC0000001':
          color = 'blue-grey-4';
          break;
        case 'AKC0000002':
          color = 'green-7';
          break;
        case 'AKC0000003':
          color = 'blue-7';
          break;
        case 'AKC0000004':
          color = 'blue-grey-4';
          break;
      }
      return 'text-' + color;
    },
    getLineKindColor2(_cd) {
      let color = '';
      switch(_cd) {
        case '반려':
          color = 'text-weight-bold text-red';
          break;
      }
      return color;
    },
  },
};
</script>